html {
  height: var(--doc-height);
}

body,
#root {
  min-height: 100vh;
  color: var(--bs-primary);
  font-size: 16px;
  line-height: 24px;
}

body {
  font-family: "ABeezee", sans-serif;
  background-color: var(--bs-info);
}

#root {
  max-width: 500px;
  margin-inline: auto;
  padding-top: 20px;
  background-color: var(--bs-secondary);
  background-image: url("./assets/images/body-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.title {
  // font-family: "Water Brush", cursive;
  font-family: "Joan", serif;
  text-align: center;
  &.cursive {
    font-family: "Water Brush", cursive;
  }
  &.medium {
    font-size: 48px;
    line-height: 48px;
  }
  &.big {
    font-size: 80px;
  }
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rounded {
  border-radius: 8px !important;
}

.text-justify {
  text-align: justify;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-info {
  background-image: url("./assets/images/paper-texture.png");
  background-position: center;
  background-size: cover;
}

.btn:focus {
  box-shadow: none;
}
p {
  margin-bottom: 2rem;
}
