:root {
  --doc-height: 100%;
}

$theme-colors: (
  "primary": rgba(84, 42, 0, 1),
  "secondary": rgba(217, 185, 126, 1),
  "success": rgba(86, 98, 61, 1),
  "danger": rgba(193, 70, 63, 1),
  "warning": rgba(190, 118, 54, 1),
  "info": rgba(166, 114, 68, 1),
  "grey": rgba(126, 108, 96, 1),
  "primarytrans": rgba(84, 42, 0, 0.8),
  "secondarytrans": rgba(217, 185, 126, 0.8),
  "successtrans": rgba(86, 98, 61, 0.9),
  "dangertrans": rgba(193, 70, 63, 0.8),
  "warningtrans": rgba(190, 118, 54, 0.8),
  "infotrans": rgba(166, 114, 68, 0.8),
  "greytrans": rgba(126, 108, 96, 0.8),
);
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Water+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Joan&display=swap");
@import "~bootstrap/scss/bootstrap.scss";
